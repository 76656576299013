import React, { useEffect, useState } from 'react';

function StickyStream() {
    const [streamUrl, setStreamUrl] = useState('');

    useEffect(() => {
        // Set the stream URL based on the current protocol
        if (window.location.protocol === 'https:') {
            setStreamUrl('http://160.39.103.190:8000/stream'); // Use HTTP for the Icecast stream
        } else {
            setStreamUrl('http://160.39.103.190:8000/stream'); // Use HTTP for the Icecast stream
        }
    }, []);

    return (
        <div>
            <audio
                style={{ width: "100%", borderRadius: 0 }}
                controls="controls"
                src={streamUrl}
                title="WBAR RADIO"
            >
                Your browser does not support the audio element.
            </audio>
        </div>
    );
}

export default StickyStream;
